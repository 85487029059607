<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005F32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <!-- <v-layout wrap> -->
    <v-layout wrap>
      <v-flex xs12 pt-4>
        <v-card elevation="0">
          <v-form v-model="addgrade" ref="addgrade">
            <v-layout>
              <v-flex xs6 text-left>
                <span class="headline">Gallery Video</span>
              </v-flex>
              <v-flex xs6 text-right>
                <v-btn
                  color="green"
                  outlined
                  small
                  @click="$router.push('/EventsPage')"
                  >Event List
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout wrap style="border-bottom: 1px dashed">
              <!-----------------upload gallery image------------------>
              <v-flex xs10>
                <v-layout wrap pt-1>




                  <v-flex xs3 pb-2 pl-2>
                    <v-card outlined>
                      <input
                        id="gallery"
                        type="file"
                        hidden
                        accept="video/*"
                        @change="browseVideo"
                      />
                      <v-btn @click="chooseVideo()" width="100%" text v-if="video">
                        <span
                          style="text-transform: none"
                          v-if="video.length <= 0"
                        >
                          Upload video
                        </span>
                        <span v-else style="color: black; text-transform: none">
                          <v-chip color="white">{{ video.name }}</v-chip>
                        </span>
                      </v-btn>
                    </v-card>
                  </v-flex>
                  <v-flex xs2>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addgrade"
                      @click="uploadVideo()"
                    >
                      Save
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- <v-flex xs12 v-if="video"
                >
                <v-layout wrap v-if="video.length > 0">
                  <v-flex
                    xs2
                    py-3
                    text-left
                    v-for="(item, i) in video"
                    :key="i"
                  >
                    <v-chip
                      @click="video"
                      close
                      @click:close="(delimgdialoge = true), (indexid = i)"
                      dark
                      color="green"
                      style="margin-right: 6px;margin-b"
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-flex> -->
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 v-if="galleryList">
                <v-layout wrap v-if="galleryList.length > 0">
                  <v-flex xs3 pa-2 v-for="(item, i) in galleryList" :key="i">
                    <v-badge
                      bordered
                      color="#005F32"
                      icon="mdi-trash-can-outline"
                      overlap style="cursor:pointer"
                      @click.native="(videoDeletedialogue=true, videoId=i)"
                    >
                      <v-card tile>
                        <iframe
                          height="300"
                          :src="mediaURLnews + item"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                          width="100%"
                        ></iframe>
                      </v-card>
                    </v-badge>
                  </v-flex>
                </v-layout>
                <v-layout v-else wrap>
                  <v-flex xs2 align-self-center>No videos found</v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
       <v-dialog width="400px" v-model="videoDeletedialogue">
            <v-card width="400px">
              <v-toolbar dark color="#005F32" dense flat class="body-2">
                <v-toolbar-title> Delete </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text"
                >Are you sure you want to delete this video?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="videoDeletedialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="deleteVideo()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
  </div>
</template>


<script>
import axios from "axios";

// import store from "./../../store";
export default {
  data() {
    return {
      msg: "",
      dialog: false,
      addgrade: false,
      // video: [],
      timeout: 5000,
      count: "",
      snackbar: false,
      appLoading: false,
      showSnackBar: false,
      todate: null,
      menu2: false,
      page: 1,
      Pagelength: 0,
      deletedialogue: false,
      dialoge: false,
      //upload image
      galleryimageArray: [],
      Images3: new FormData(),
      cropGalleryImageDialog: false,
      currentGalleryImage: null,
      selectedFiles: null,
      selectedFiles2: null,
      selectedFiles3: null,
      image: null,
      galleryList: [],
      //video
      video: [],
      videoData: new FormData(),
      videoId:null,
      videoDeletedialogue:false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    chooseVideo() {
      document.getElementById("gallery").click();
    },
    browseVideo(file) {
      this.video = file.target.files[0];
      console.log("the video is", this.video);
    },
    // browseVideo(file) {
    //   var got = file.target.files;
    //   if (got.length<5) {
    //     for (let i = 0; i < got.length; i++) {
    //       this.video.push(got[i]);
    //     }
    //     console.log("the video is", this.video);
    //   } else {
    //     this.msg = "Maximum 5 can be uploaded";
    //     this.showSnackBar = true;
    //   }
    // },
    uploadVideo() {
      this.videoData.append("video", this.video);
      this.videoData.append("id", this.$route.query.id);
      axios({
        method: "post",
        url: "/media/upload/event/video",
        headers: {
          "token": localStorage.getItem("token"),
        },
        data: this.videoData,
      })
        .then((response) => {
            this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.video=[];
            this.getData();
          } else {
            console.log("failed to upload video");
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
 deleteVideo() {
      axios({
        method: "post",
        url: "/event/removeVideo",
        headers: {
          "token": localStorage.getItem("token"),
        },
        data:{
          position:this.videoId,
          id:this.$route.query.id,
        }
      })
        .then((response) => {
            this.appLoading = false;
          if (response.data.status==true) {
            this.videoDeletedialogue=false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
             this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/admin/event/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: this.page,
          limit: 15,
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.galleryList = response.data.data.videos;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // gallery image upload
    imageCropper3(payload) {
      this.cropGalleryImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages3(payload.image);
      }
    },
    uploadImages3(event) {
      console.log("upload image3");
      if (this.galleryimageArray.length < 10) {
        this.currentGalleryImage = URL.createObjectURL(event.target.files[0]);
        this.cropGalleryImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages3(image) {
      this.selectedFiles3 = image;
      this.Images3.append("galleryImage", this.selectedFiles3);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.galleryimageArray.push(urls);
        if (this.galleryimageArray) {
          this.image = this.galleryimageArray[0];
        }
      };
      console.log("image3=", this.selectedFiles3);
    },
    //upload image3
    uploadAllImages3() {
      this.appLoading = true;
      this.Images3.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/media/upload/event/images/gallery",
        data: this.Images3,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.Images3 = null;
            this.galleryimageArray = [];
            // window.location.reload();
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            // this.$router.push("/Seller/Product/Added/" + item);
          } else {
            this.Images3 = new FormData();
            this.galleryimageArray = [];
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //
  },
};
</script>







